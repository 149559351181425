import { abortNavigation, createError, defineNuxtRouteMiddleware } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import type { RouteLocationNormalized } from 'vue-router';

import { PAGE_NOT_FOUND_404 } from '@/constants/error.const';
import useProductStore from '@/stores/product.store';
import { isDirectAccess } from '@/utils/common.util';

export default defineNuxtRouteMiddleware(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const { name: toName, meta } = to;
    const { name: fromName } = from;
    const productStore = useProductStore();
    const { currentProductDetailType } = storeToRefs(productStore);

    const accessibleProductTypes = meta.accessibleProductTypes as string[];

    const accessible =
      !accessibleProductTypes ||
      accessibleProductTypes.length === 0 ||
      accessibleProductTypes.includes(currentProductDetailType.value);
    if (!accessible) {
      if (isDirectAccess(toName, fromName)) {
        throw createError({
          statusCode: PAGE_NOT_FOUND_404,
          message: 'Page not found'
        });
      }
      return abortNavigation();
    }
  }
);
